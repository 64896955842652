import React from 'react'
import { graphql, Link } from 'gatsby'
import {
	mapEdgesToNodes,
	filterOutDocsWithoutSlugs,
	filterOutDocsPublishedInTheFuture
} from '../lib/helpers'
import BlogPostPreviewList from '../components/blog-post-preview-list'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import { FearCard } from '../components/cards/fearCard'

export const query = graphql`

  query FearsOverviewPageQuery {
      page: sanityPlainPage(_id: { eq: "fearsOverviewPage" }) {
        id
        slug {
          current
        }
        showBanner
        seoTitle
		seoKeywords
		hideOverlay
        seoDescription
        bannerTitle
        bannerSubTitle
        bannerImage {
          asset {
			url
			fluid {
				...GatsbySanityImageFluid
			}
          }
        }
        bannerCta {
          label
          url
        }
      }
    fearPages: allSanityFearPage(sort: {
		fields: [fear___name]
		order: ASC
   }) {
      edges {
        node {
		  id,
		  slug {
			current
		  }
		  bannerImage {
			asset {
			  url
			  fluid {
				  ...GatsbySanityImageFluid
			  }
			}
		  }
		  fear {
			id
			name
			description
		  }
        }
      }
    }
  }
`

const FearPage = ({ data = {}, errors }) => {

	if (errors) {
		return (
			<Layout page={data.page || {}}>
				<GraphQLErrorList errors={errors} />
			</Layout>
		)
	}

	const site = data.site || {}

	const fearPages = (data.fearPages || {}).edges || []


	if (!site) {
		throw new Error(
			'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
		)
	}

	return (
		<Layout page={data.page}>
			<Container>
				<section className="bg-gray-50 px-4 4xl:px-0 py-16">
					<div className="max-w-6xl mx-auto">
						<h3 className="font-roboto-slab text-3xl text-gray-900">Bij welke angst kunnen wij je helpen?</h3>
						{/* <div className="block flex flex-col md:flex-row justify-around mt-8"> */}
						<div className="grid grid-cols-1 md:grid-cols-2 md:grid-cols-2 gap-8 mt-8">

							{
								fearPages.map(({ node }) => {
									return ( <FearCard node={node} /> )
								})
							}

						</div>
					</div>
				</section>
			</Container>
		</Layout>
	)
}

export default FearPage
